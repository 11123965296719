import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

export const ImageWithCtaSection = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/backpack-walking.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="relative bg-warm-grey-800">
      <div className="h-56 bg-bb-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img fluid={data.placeholderImage.childImageSharp.fluid} className="w-full h-full object-cover" />
      </div>
      <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <div className="text-base leading-6 font-semibold uppercase tracking-wider text-warm-grey-300">
            Förskolan Brandbilen, Örnsköldsvik
          </div>
          <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            Ute, blöt och glad
          </h2>
          <p className="mt-3 text-lg leading-7 text-warm-grey-300">
            Att vara tillsammans ute i naturen har varit en viktig del av Brandbilen sedan starten. Vi följer årstidsväxlingarna och utforskar vår närmiljö. Vi går ofta från gården på promenader och till skogen. Förskolan har nära till många natursköna platser. Många av våra måltider äter vi ute så länge vädret tillåter.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Link to="/intresseanmalan" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-warm-grey-900 bg-white hover:text-warm-grey-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Kom igång
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}