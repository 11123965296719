import React from "react"

export const ImportantForUs = () => {
  return (
    <div className="bg-warm-grey-50 pt-12 sm:pt-16">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl leading-9 font-extrabold text-warm-grey-900 sm:text-4xl sm:leading-10">
            Det här är viktigt för oss
          </h2>
          <p className="mt-3 text-xl leading-7 text-warm-grey-500 sm:mt-4">
            All mat lagas från grunden med bra råvaror och vi har två mindre
            grupper vilket innebär gott om tid och plats för varje individ.
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-warm-grey-50"></div>
          <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <div className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <p className="p-6 border-b border-warm-grey-100 sm:border-0 sm:border-r text-5xl leading-none font-extrabold text-bb-red-600 flex items-center justify-center">
                  Natur
                </p>
                <p className="border-t border-b border-warm-grey-100 p-6 sm:border-0 sm:border-l sm:border-r text-4xl leading-none font-extrabold text-bb-red-600 flex items-center justify-center text-center">
                  Hemlagad mat
                </p>
                <p className="border-t border-warm-grey-100 p-6 sm:border-0 sm:border-l text-4xl leading-none font-extrabold text-bb-red-600 flex items-center justify-center text-center">
                  Små barngrupper
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
