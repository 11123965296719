import React from "react"
import { Link } from "gatsby"

export const HeroCtaSection = () => {
  return (
    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-warm-grey-900 sm:text-5xl sm:leading-none md:text-6xl">
          Snällhet
          <br className="xl:hidden" />{" "}
          <span className="text-bb-red-600">som smittar</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-warm-grey-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          På Brandbilen är det viktigt att vara ute mycket, att barnen får äta
          bra hemlagad mat. En förskola i det mindre formatet. En stabil
          personalgrupp, mindre barngrupper, där du lär känna de andra
          vårdnadshavarna väl och där har du möjlighet att bidra med ditt
          engagemang. Låter det intressant?
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <Link
              to="/intresseanmalan"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:shadow-outline-bb-red transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Kom igång
            </Link>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <Link
              to="/om-brandbilen"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-bb-red-600 bg-white hover:text-bb-red-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Läs mer
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
