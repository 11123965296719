import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import * as sections from "../components/sections"

const IndexPage = () => {
  return (
    <>
      <SEO title="Välkommen till oss" />
      <Layout>
        <sections.HeroCtaSection />
        <sections.ImageWithCtaSection />
        <sections.ImportantForUs />
        <sections.BigTestimonial />
        <sections.ParentsTestimonial />
        <sections.FinalCta />
      </Layout>
    </>
  )
}



export default IndexPage
