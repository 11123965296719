import React from "react"
import { Link } from 'gatsby'

export const SmallCta = () => {
  return (
    <div className="bg-warm-grey-50">
      <div className="max-w-screen-xl mx-auto py-12 lg:py-16 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-warm-grey-900 sm:text-4xl sm:leading-10">
          Låter det bra?
        </h2>
        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <Link to="/intresseanmalan" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Kom igång
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}